import React from 'react';
import Layout from '../../components/Layout';
import CostSupport, {
  costAndSupportBody
} from '../../components/CostSupport/CostSupport';
import Hero2, { costAndSupport } from '../../components/Hero/Hero2';
import TwoColor, {
  costAndSupportTwoColor
} from '../../components/TwoColor/TwoColor';
import GlobalCostAndSavings from '../../components/GlobalCostAndSavings/global-cost-and-savings';
import GlobalFaq from '../../components/GlobalFaq/global-faq';

const Home = () => {
  return (
    <Layout section="UF" page="/uterine-fibroids/faq/">
      <>
        <GlobalFaq />

        {/* <Hero2 {...costAndSupport} />
        <TwoColor {...costAndSupportTwoColor} />
        {costAndSupportBody.map((costAndSupportProps, index) => (
          <CostSupport {...costAndSupportProps} key={index} />
        ))} */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/uterine-fibroids/faq/';
  const title =
    'Uterine Fibroid FAQs | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Safety Info';
  const pageTitle =
    'Uterine Fibroid FAQs | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Safety Info';
  const description =
    'Get answers to FAQs about MYFEMBREE® for heavy menstrual bleeding due to uterine fibroids in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
